var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import NewsletterService from "projects/EVIDENCEB/services/NewsletterService";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ContactFormField from "./ContactFormField";
import DisplayResult from "./DisplayResult";
import HoneyPot from "./HoneyPot";
var ContactForm = function () {
    var honey_key = "ip"; //useEnvironment();
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState(false), sendingError = _b[0], setSendingError = _b[1];
    var _c = useState(false), formSubmitted = _c[0], setFormSubmitted = _c[1];
    var _d = useForm({
        defaultValues: {
            subject: "",
            lastName: "",
            firstName: "",
            email: "",
            message: ""
        }
    }), control = _d.control, handleSubmit = _d.handleSubmit, reset = _d.reset;
    var onSubmit = function (data, e) {
        var _a;
        var result = __assign(__assign({}, data), (_a = {}, _a[honey_key] = document.getElementById(honey_key).value, _a));
        setLoader(true);
        setFormSubmitted(true);
        NewsletterService.sendMail(result).then(function (data) {
            setLoader(false);
        }, function (error) {
            setSendingError(true);
            setLoader(false);
        });
    };
    var onError = function (errors, e) { return console.log(errors, e); };
    var clearFormAndMessage = function () {
        reset();
        setFormSubmitted(false);
        setSendingError(false);
    };
    return (_jsx(_Fragment, { children: formSubmitted ? (_jsx(DisplayResult, { loading: loader, sendingError: sendingError, handleReturn: clearFormAndMessage }, void 0)) : (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit, onError) }, { children: [_jsx(Typography, { children: "* champs obligatoire" }, void 0), _jsx(HoneyPot, { name: honey_key }, void 0), _jsx(ContactFormField, { control: control, required: true, fieldName: "subject", label: "Sujet", icon: "/ContactForm-bubble.svg", inputMaxLength: 100, customError: {
                        required: "Veuillez renseigner le sujet de votre demande"
                    } }, void 0), _jsxs(Grid, __assign({ container: true, direction: "row", justifyContent: "space-between" }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, lg: 5.5 }, { children: _jsx(ContactFormField, { control: control, required: true, fieldName: "lastName", label: "Nom", icon: "/ContactForm-user.svg", customError: {
                                    required: "Veuillez renseigner votre nom"
                                } }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, lg: 5.5 }, { children: _jsx(ContactFormField, { control: control, required: true, fieldName: "firstName", label: "Pr\u00E9nom", icon: "/ContactForm-user.svg", customError: {
                                    required: "Veuillez renseigner votre prénom"
                                } }, void 0) }), void 0)] }), void 0), _jsx(ContactFormField, { control: control, required: true, fieldName: "email", label: "Email", icon: "/ContactForm-envelope.svg", ariaDescribedByText: "Format attendu: nomprenom@domain.fr", customError: {
                        required: "Veuillez renseigner votre adresse e-mail",
                        pattern: "Veuillez saisir une adresse e-mail valide. Par exemple : john.doe@access42.net"
                    }, regexValidation: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim }, void 0), _jsx(ContactFormField, { control: control, required: true, fieldName: "message", label: "Message", icon: "/ContactForm-bubble-ellipsis.svg", multiline: true, inputMaxLength: 255, customError: {
                        required: "Veuillez nous expliquer votre demande"
                    } }, void 0), _jsxs(Button, __assign({ variant: "contained", disabled: loader, disableRipple: true, type: "submit", endIcon: _jsx("img", { alt: "", src: "/ContactForm-send.svg" }, void 0), sx: { mt: 1, pb: loader ? 1.25 : 1 } }, { children: ["Envoyer mon message", loader && (_jsx(LinearProgress, { sx: { position: "absolute", left: 0, right: 0, bottom: 0, borderRadius: "0 0 6px 6px" } }, void 0))] }), void 0)] }), void 0)) }, void 0));
};
export default ContactForm;
