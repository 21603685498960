var _a;
import { createTheme } from "@mui/material/styles";
var overrideKey = "customization";
var border = "#3B29AB";
var common = {
    consent: {
        wrap: {
            background: "white",
            borderColor: border
        }
    }
};
var primary = {
    light: "#F4F7FE",
    main: "#3B29AB",
    dark: "#1B134D"
};
var secondary = {
    light: "#F4F7FE",
    main: "#3B29AB",
    dark: "#1B134D"
};
var greyColor = { main: "#666E9A" };
var typography = {
    fontFamily: '"Roboto"',
    h0: {
        color: primary.dark,
        fontSize: "2.25rem",
        lineHeight: "3rem",
        letterSpacing: "-0.01rem",
        fontWeight: 700
    },
    h1: {
        color: primary.dark,
        fontSize: "1.5rem",
        lineHeight: "1.76rem",
        letterSpacing: "0.05rem",
        fontWeight: 700
    },
    h2: {
        color: primary.dark,
        fontSize: "1.25rem",
        lineHeight: "1.465rem",
        letterSpacing: "0.05rem",
        fontWeight: 700
    },
    h3: {
        color: primary.dark,
        fontSize: "1.125rem",
        lineHeight: "1.32rem",
        letterSpacing: "0.05rem",
        fontWeight: 700
    },
    h4: {
        color: primary.dark,
        fontSize: "1rem",
        lineHeight: "1.17rem",
        letterSpacing: "0.05rem",
        fontWeight: 700
    },
    h5: {
        color: primary.dark,
        fontSize: "1.5rem",
        fontWeight: "bold"
    },
    h6: {
        color: primary.light,
        fontSize: "1.2rem",
        fontWeight: "bold"
    },
    subtitle1: {
        color: primary.dark,
        fontSize: "1rem",
        lineHeight: "1.17rem",
        letterSpacing: "0.05rem",
        fontWeight: 500
    },
    subtitle2: {
        color: primary.dark,
        fontSize: "0.875rem",
        lineHeight: "1rem",
        fontWeight: 500,
        letterSpacing: "0.05rem"
    },
    body1: {
        color: "#666E9A"
    },
    body2: {
        color: "##666E9A"
    },
    textArticle: {
        color: greyColor.main,
        fontSize: "1rem",
        lineHeight: "1.75rem",
        letterSpacing: "-0.019rem",
        fontWeight: 400
    },
    text: {
        color: greyColor.main,
        fontSize: "0.875rem",
        lineHeight: "1rem",
        letterSpacing: "0.05rem",
        fontWeight: 400
    },
    textMedium: {
        color: greyColor.main,
        fontSize: "0.75rem",
        lineHeight: "0.875rem",
        letterSpacing: "0.05rem",
        fontWeight: 500
    },
    smallText: {
        color: greyColor.main,
        fontSize: "0.75rem",
        lineHeight: "0.875rem",
        letterSpacing: "0.05rem",
        fontWeight: 400
    },
    caption: {
        color: greyColor.main,
        fontSize: "0.625rem",
        lineHeight: "0.75rem",
        letterSpacing: "0.05rem",
        fontWeight: 400
    }
};
var studentTheme = createTheme((_a = {
        student: true,
        professor: false,
        breakpoints: {
            values: {
                xs: 0,
                sm: 420,
                md: 900,
                lg: 1276,
                xl: 1536
            }
        },
        typography: typography,
        palette: {
            primary: primary,
            secondary: secondary,
            common: common,
            greyColor: greyColor,
            border: border
        },
        components: {
            SideBox: {
                styleOverrides: {
                    root: {
                        position: "absolute",
                        width: "188px",
                        height: "188px",
                        transform: "matrix(-0.87, -0.5, -0.5, 0.87, 0, 0)",
                        borderRadius: "136px",
                        border: 1,
                        borderColor: "#3B29AB",
                        left: "-72px"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: "6px",
                        padding: "8px 16px",
                        letterSpacing: "0.05rem",
                        textTransform: "none",
                        border: "1px solid #3B29AB"
                    },
                    endIcon: {
                        marginLeft: "12px",
                        marginRight: "6px",
                        "& > *:nth-of-type(1)": {
                            fontSize: "0.75rem"
                        }
                    },
                    contained: {
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #3B29AB",
                        boxShadow: "2px 3px 0px #3B29AB",
                        padding: "8px 16px 8px 20px",
                        borderRadius: "29px",
                        color: "#3B29AB",
                        "&:hover": {
                            background: "#EEF3FF",
                            boxShadow: "2px 3px 0px #3B29AB"
                        }
                    },
                    outlined: {
                        padding: "12px 24px",
                        borderRadius: "39px",
                        background: "transparent",
                        boxShadow: "rgb(255,255,255, .95) 2px 3px 0px -1px, #3B29AB 2px 3px"
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#DEE1F2"
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: secondary.light,
                        borderRadius: 7,
                        padding: 5
                    },
                    underline: {
                        "&::before": {
                            border: "none"
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontSize: "1.2rem",
                        minHeight: 26
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: "100%"
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        textDecoration: "none"
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 10
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: "4px 6px 0px #3B29AB",
                        border: "1px solid #3B29AB",
                        borderTopLeftRadius: "40px",
                        borderRadius: "40px",
                        "&:first-of-type, &:last-of-type": {
                            borderRadius: "40px"
                        },
                        opacity: 0.7,
                        "&:hover": {
                            opacity: 1
                        },
                        "&:before": {
                            position: "initial"
                        }
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    content: {
                        color: primary.dark,
                        padding: "20px 0",
                        margin: 0,
                        paddingRight: "30px",
                        "&.Mui-expanded": {
                            margin: 0,
                            borderBottom: "1px solid",
                            borderColor: "#DEE1F2"
                        }
                    },
                    expandIconWrapper: {
                        position: "absolute",
                        right: 34,
                        color: primary.main,
                        zIndex: 2
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: "12px 28px 16px"
                    }
                }
            },
            MuiSnackbar: {
                styleOverrides: {
                    root: {
                        maxWidth: "auto"
                    }
                }
            }
        }
    },
    _a[overrideKey] = {
        Text: {
            text: {
                color: primary.main,
                fontFamily: "Work Sans",
                fontSize: "1.1rem",
                lineHeight: "1.7rem"
            }
        },
        Attachment: {
            container: {
                "&:hover": {
                    backgroundColor: "#D1EBFF"
                }
            }
        }
    },
    _a));
export default studentTheme;
