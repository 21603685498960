var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, CircularProgress, Divider, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "cms/components/front-office/contents/Link";
import SiteContext from "cms/components/shared/SiteContext";
import { isEmail } from "cms/utils/isEmail";
import Social from "projects/EVIDENCEB/components/Social";
import NewsletterService from "projects/EVIDENCEB/services/NewsletterService";
import React, { useContext } from "react";
import ElementGraphique from "./ElementGraphique";
import InformationPopover from "./InformationPopover";
var textFieldStyle = {
    borderColor: { md: "white", xs: "greyColor.main" },
    mb: { md: 0, xs: "1rem" },
    "& p": {
        bgcolor: "inherit"
    },
    "label + &": {
        color: { md: "white", xs: "primary.main" }
    },
    label: {
        color: { md: "#DEE1F2", xs: "#666E9A" }
    },
    "#email": {
        color: { md: "white", xs: "primary.main" }
    },
    "#email-helper-text": { color: { md: "#ff6e51", xs: "error.main" } },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
        color: { md: "#ff6e51", xs: "error.main" }
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: { md: "#ff6e51", xs: "error.main" }
    },
    "& label.Mui-focused": {
        color: { md: "white", xs: "primary.main" }
    },
    "& .MuiInputLabel-root": {
        '&[data-shrink="true"]': {
            pl: 1
        },
        '&[data-shrink="false"]': {
            pt: 0.125
        }
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: { md: "white", xs: "greyColor.main" },
            borderRadius: "9px",
            pr: 0,
            padding: "8.5px 14px!important",
            borderTopRightRadius: { md: "0px", xs: "9px" },
            borderBottomRightRadius: { md: "0px", xs: "9px" }
        },
        "&:hover fieldset": {
            borderColor: "inherit"
        },
        "&.Mui-focused fieldset": {
            borderColor: { md: "white", xs: "greyColor.main" }
        }
    },
    "& .MuiOutlinedInput-input": {
        color: "#FFFFFF"
    },
    "& .Mui-error ": {
        fontSize: "1rem",
        pt: 1
    }
};
var titleStyled = {
    fontSize: { md: "2.375rem" },
    lineHeight: { md: "3.20rem" },
    color: { md: "primary.light" },
    mb: { xs: 1, md: 0.25 },
    textAlign: { xs: "center", md: "left" }
};
var subtitleStyled = {
    color: { xs: "greyColor.main", md: "primary.light" },
    bgcolor: "unset!important",
    "& > a": {
        fontSize: "0.75rem",
        fontStyle: "italic",
        color: { xs: "greyColor.main", md: "primary.light" },
        textDecoration: "underline"
    },
    mx: { xs: "auto", sm: "auto", md: "unset" },
    textAlign: { xs: "center", sm: "center", md: "right" }
};
var buttonStyle = {
    bgcolor: { md: "white", xs: "primary.main" },
    color: { md: "primary.main", xs: "primary.light" },
    textTransform: "none",
    letterSpacing: "0.05em",
    px: "53px",
    py: "6px",
    height: { md: "45px" },
    fontSize: { xs: "0.875rem", md: "1.2rem" },
    borderTopLeftRadius: { md: "0px", xs: "9px" },
    borderBottomLeftRadius: { md: "0px", xs: "9px" },
    "button&:hover": {
        bgcolor: { md: "white", xs: "primary.dark" },
        opacity: { md: 0.7 }
    }
};
var imageStyled = {
    width: "260px",
    position: "absolute",
    left: "-50px",
    bottom: "-45px",
    display: { xs: "none", md: "block" }
};
var socialsStyledMd = {
    mt: "2rem",
    flexDirection: "row",
    alignItems: "center",
    color: "#fff",
    display: { xs: "none", md: "flex" },
    justifyContent: "flex-start",
    "& a": {
        mr: 2.5
    },
    "& i": {
        color: "primary.light",
        fontSize: 24
    }
};
var socialsStyledXs = {
    display: { xs: "block", md: "none" },
    flexDirection: "column",
    "& a": {
        display: "inline-block",
        mt: 2,
        mr: 2.5
    },
    "& i": {
        color: "primary.main",
        fontSize: 24
    },
    color: { md: "white", xs: "greyColor.main" },
    mr: { md: 2, xs: 0 },
    textAlign: { xs: "center", md: "left" }
};
var stylepadding = {
    position: "relative",
    background: {
        xs: "none",
        md: "linear-gradient(84.43deg, #3928A7 0.24%, #1B134D 146.48%)"
    },
    zIndex: 5,
    overflow: "visible",
    borderRadius: "9px",
    margin: {
        xs: "0 0 -103px",
        md: "-81px 0 -10px"
    },
    padding: {
        xs: "10px",
        md: "60px 81px 53px 200px"
    },
    height: "348px",
    width: "100%"
};
var Newsletter = function (props) {
    var id = props.id, title = props.title, subtitle = props.subtitle, link = props.link, image = props.image, textRS = props.textRS, listId = props.listId, others = __rest(props, ["id", "title", "subtitle", "link", "image", "textRS", "listId"]);
    var _a = link || {}, linkTitle = _a.title, page = _a.page, url = _a.url, external = _a.external;
    var site = useContext(SiteContext);
    var siteSettings = (site || {}).settings;
    var _b = (siteSettings || {}).socialNetworks, socialNetworks = _b === void 0 ? [] : _b;
    if (socialNetworks && !Array.isArray(socialNetworks)) {
        socialNetworks = [socialNetworks];
    }
    var _c = React.useState(), success = _c[0], setSuccess = _c[1];
    var _d = React.useState(""), error = _d[0], setError = _d[1];
    var _e = React.useState(false), loader = _e[0], setLoader = _e[1];
    var _f = React.useState(""), email = _f[0], setEmail = _f[1];
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    var addToNewsletterSendinblue = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (!isEmail(email)) {
            setError("Veuillez saisir une adresse e-mail valide. Par exemple : john.doe@access42.net");
        }
        else {
            setLoader(true);
            NewsletterService.inscription(email, listId ? listId : 1)
                .then(function (response) {
                setLoader(false);
                setSuccess(true);
                setEmail("");
                setTimeout(function () {
                    setSuccess(undefined);
                }, 3000);
            })
                .catch(function (error) {
                setLoader(false);
                setSuccess(false);
                if (error.response.status === 401) {
                    setError(error.response.data);
                }
                else {
                    setError("Une erreur s'est produite, peut-être êtes vous déjà inscrit à la newsletter, si le problème persiste veuillez contacter l'administrateur");
                }
            });
        }
    };
    var handleModifyEmail = function (e) {
        setError("");
        setEmail(e.target.value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(ElementGraphique, {}, void 0) }, void 0), _jsxs(Box, __assign({ sx: stylepadding }, { children: [_jsx(Typography, __assign({ variant: "h1", component: "h4", sx: titleStyled }, { children: title }), void 0), _jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "baseline" }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row", alignItems: "center" }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ component: "p", variant: isMobile ? "smallText" : "subtitle1", sx: subtitleStyled }, { children: subtitle }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(InformationPopover, {}, void 0) }), void 0)] }), void 0), _jsx(Grid, __assign({ item: true }, { children: (url || page) && (_jsx(Typography, __assign({ variant: isMobile ? "smallText" : "subtitle1", component: "p", sx: subtitleStyled }, { children: _jsx(Link, __assign({ page: page, url: url, external: external, title: linkTitle }, { children: linkTitle }), void 0) }), void 0)) }), void 0)] }), void 0), _jsx(Box, __assign({ component: "form", autoComplete: "off", noValidate: true, sx: { my: 2 } }, { children: success ? (_jsx(Typography, __assign({ sx: { color: "white", bgcolor: "inherit!important", textAlign: "center" } }, { children: "Merci pour votre inscription" }), void 0)) : (_jsx(_Fragment, { children: loader ? (_jsx(CircularProgress, {}, void 0)) : (_jsxs(Box, __assign({ sx: {
                                    display: "flex",
                                    flexDirection: { md: "row", xs: "column" }
                                } }, { children: [_jsx(TextField, { id: "email", label: "Email : nomprenom@domain.fr", variant: "outlined", sx: __assign({}, textFieldStyle), size: "small", error: error.length !== 0, name: "email", type: "email", onChange: handleModifyEmail, value: email, inputProps: {
                                            "aria-required": true
                                        }, helperText: error && (_jsxs(_Fragment, { children: [_jsx(WarningIcon, { sx: { fontSize: "1em" } }, void 0), " ", error] }, void 0)) }, void 0), _jsx(Button, __assign({ type: "submit", color: "primary", sx: buttonStyle, onClick: addToNewsletterSendinblue }, { children: "S'inscrire" }), void 0)] }), void 0)) }, void 0)) }), void 0), _jsxs(Box, __assign({ sx: socialsStyledMd }, { children: [_jsx(Typography, __assign({ variant: "h1", component: "span", sx: {
                                    color: { md: "white", xs: "greyColor.main" },
                                    mr: { md: 2, xs: 0 },
                                    textAlign: { xs: "right", md: "left" }
                                } }, { children: textRS }), void 0), _jsx(Social, { socialNetworks: socialNetworks }, void 0)] }), void 0), _jsx(Box, __assign({ sx: socialsStyledXs }, { children: _jsxs(Divider, __assign({ sx: {
                                display: "flex",
                                width: "100%",
                                bgcolor: "primary.light",
                                color: "greyColor.main",
                                mt: 2,
                                mb: 2
                            } }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", component: "span" }, { children: textRS }), void 0), _jsx(Box, __assign({ sx: { display: "flex", justifyContent: "center", alignItems: "center", flex: 1 } }, { children: _jsx(Social, { socialNetworks: socialNetworks }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Box, __assign({ sx: imageStyled }, { children: image }), void 0)] }), void 0)] }, void 0));
};
export default Newsletter;
