var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Popover, Typography } from "@mui/material";
import React from "react";
var InformationPopover = function () {
    // const theme = useTheme();
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popover" : undefined;
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ "aria-describedby": id, onClick: handleClick, sx: { color: "white" } }, { children: _jsx(InfoIcon, {}, void 0) }), void 0), _jsx(Popover, __assign({ id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                } }, { children: _jsx(Typography, __assign({ sx: { p: 2 } }, { children: "Vous faites partie de l\u2019\u00C9ducation nationale ? Inscrivez-vous et recevez les derni\u00E8res informations." }), void 0) }), void 0)] }, void 0));
};
export default InformationPopover;
